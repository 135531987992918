
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { useCreatePlan, useUpdatePlan, usePlan } from '@/composables/api';
import {
  PartialPlan,
  PlanInitPeriodUnit,
  PlanRecurringPeriodUnit,
  PlanShow,
  PlanBest,
  PlanDownloadable
} from '@/interfaces/Plan';
import useFormErrors from '@/utils/validate';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialPlan = {
  name: '',
  title: '',
  type: '',
  oncePrice: null,
  onceCount: null,
  initPeriodCount: null,
  initPeriodUnit: PlanInitPeriodUnit.DAY,
  initOriginalPrice: null,
  initDiscountPrice: null,
  recurringPeriodCount: null,
  recurringPeriodUnit: PlanRecurringPeriodUnit.DAY,
  recurringOriginalPrice: null,
  recurringDiscountPrice: null,
  downloadable: PlanDownloadable.YES,
  show: PlanShow.YES,
  isBest: PlanBest.NO,
  sort: null
};

const periodUnitOptions = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const planId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const dynamicRules = ref({
      oncePrice: [],
      onceCount: [],
      initialPeriodCount: [],
      initialPeriodUnit: [],
      initialOrigPrice: [],
      recurringPeriodCount: [],
      recurringPeriodUnit: [],
      recurringOrigPrice: []
    });
    const { isLoading: isCreatedLoading, mutate: create } = useCreatePlan();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdatePlan();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { t } = useI18n();

    const rules = {
      name: [
        {
          required: true
        }
      ],
      title: [
        {
          required: true
        }
      ],
      type: [
        {
          required: true
        }
      ],
      sort: [
        {
          required: true
        }
      ],
      segpayId: [
        {
          required: true
        }
      ],
      natsId: [
        {
          required: true
        }
      ],
      xsaleId: [
        {
          required: false
        }
      ]
      // status: [
      //   {
      //     required: true,
      //     trigger: 'change'
      //   }
      // ]
    };

    const { data, dataUpdatedAt } = usePlan({ planId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    watch(() => formValues.value.type, (newType) => {
      if (newType === 'once') {
        dynamicRules.value.oncePrice = [{ required: true, message: 'Price is required', trigger: 'blur' }];
        dynamicRules.value.onceCount = [{ required: true, message: 'Count is required', trigger: 'blur' }];
        dynamicRules.value.initialPeriodCount = [{ required: false }];
        dynamicRules.value.initialPeriodUnit = [{ required: false }];
        dynamicRules.value.initialOrigPrice = [{ required: false }];
        dynamicRules.value.recurringPeriodCount = [{ required: false }];
        dynamicRules.value.recurringPeriodUnit = [{ required: false }];
        dynamicRules.value.recurringOrigPrice = [{ required: false }];
      } else {
        dynamicRules.value.oncePrice = [{ required: false }];
        dynamicRules.value.onceCount = [{ required: false }];
        dynamicRules.value.initialPeriodCount = [{ required: true, message: 'Initial Period Count is required', trigger: 'blur' }];
        dynamicRules.value.initialPeriodUnit = [{ required: true, message: 'Initial Period Unit is required', trigger: 'blur' }];
        dynamicRules.value.initialOrigPrice = [{ required: true, message: 'Initial Orig Price is required', trigger: 'blur' }];
        dynamicRules.value.recurringPeriodCount = [{ required: true, message: 'Recurring Period Count is required', trigger: 'blur' }];
        dynamicRules.value.recurringPeriodUnit = [{ required: true, message: 'Recurring Period Unit is required', trigger: 'blur' }];
        dynamicRules.value.recurringOrigPrice = [{ required: true, message: 'Recurring Orig Price is required', trigger: 'blur' }];
      }
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { planId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      path: '/plans'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    path: '/plans'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      IntBoolean,
      formValues,
      dynamicRules,
      formRef,
      rules,
      PlanInitPeriodUnit,
      PlanShow,
      PlanBest,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      periodUnitOptions,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
